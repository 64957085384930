import React from 'react';
import { Button, Space, Tag } from 'antd';
import { AccountData } from '../../../types/residualTypes';
import { getWirelineResidualsLabel } from '../../../utils/wirelineResidualsMapper';

interface AccountHeaderProps {
  accountData: AccountData;
  onEditStatus: () => void;
  accountId: string;
}

const AccountHeader: React.FC<AccountHeaderProps> = ({ 
  accountData, 
  onEditStatus,
  accountId
}) => {
  const residualLabel = getWirelineResidualsLabel(accountData.foxyflow_wirelineresiduals);

  return (
    <div>
      <h1 style={{ 
        fontSize: '24px', 
        marginBottom: '16px',
        color: '#D1D5DA',
        fontWeight: 500
      }}>
        {accountData.name}
      </h1>
      <Space size="middle" style={{ marginBottom: '16px' }}>
        <Button 
          type="primary" 
          onClick={onEditStatus}
          style={{
            background: '#1890ff',
            borderColor: '#1890ff'
          }}
        >
          Status Update
        </Button>
        <Button
          onClick={() => window.open(`https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&forceUCI=1&pagetype=entityrecord&etn=account&id=${accountId}`, '_blank')}
          style={{
            background: '#1c2333',
            borderColor: '#30363d',
            color: '#D1D5DA'
          }}
        >
          Foxy CRM Account
        </Button>
      </Space>
      <div style={{ marginBottom: '24px' }}>
        <Space size="middle">
          <Tag color="blue" style={{ background: '#1c2333', borderColor: '#30363d' }}>
            <span style={{ color: '#D1D5DA' }}>Wireline Residuals:</span>{' '}
            <span style={{ color: '#1890ff' }}>{residualLabel}</span>
          </Tag>
          <Tag color="green" style={{ background: '#1c2333', borderColor: '#30363d' }}>
            <span style={{ color: '#D1D5DA' }}>Services:</span>{' '}
            <span style={{ color: '#52c41a' }}>Fibre Internet</span>
          </Tag>
        </Space>
      </div>
    </div>
  );
};

export default AccountHeader; 