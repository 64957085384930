import { msalInstance, loginRequestDynamics, loginRequestGraph } from './authConfig';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Client } from "@microsoft/microsoft-graph-client";
import { Group, User } from "@microsoft/microsoft-graph-types";

// Function to get current user's IDs
export const getCurrentUserIds = async (): Promise<{ azureId: string; email: string }> => {
  try {
    const accessToken = await getGraphAccessToken();
    const client = Client.init({
      authProvider: (callback: (error: any, token: string) => void) => {
        callback(null, accessToken);
      },
    });

    // Get user info from Microsoft Graph
    const user = await client.api('/me').get() as User;
    return {
      azureId: user.id || '',  // Azure AD Object ID
      email: user.userPrincipalName || ''  // Email can be used to query Dynamics user
    };
  } catch (error) {
    console.error('Error fetching user IDs:', error);
    throw error;
  }
};

// Define group IDs - these should match your Azure AD group IDs
const ADMIN_GROUP = 'FOXY_LEDGER_ADMIN';
const USER_GROUP = 'FOXY_LEDGER_USER';

export const getDynamicsAccessToken = async (): Promise<string> => {
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    throw new Error('No active account! Please sign in first.');
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequestDynamics,
      account: account as AccountInfo
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup(loginRequestDynamics);
      return response.accessToken;
    }
    throw error;
  }
};

export const getGraphAccessToken = async (): Promise<string> => {
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    throw new Error('No active account! Please sign in first.');
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequestGraph,
      account: account as AccountInfo
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup(loginRequestGraph);
      return response.accessToken;
    }
    throw error;
  }
};

export const getUserGroups = async (): Promise<string[]> => {
  try {
    const accessToken = await getGraphAccessToken();

    const client = Client.init({
      authProvider: (callback: (error: any, token: string) => void) => {
        callback(null, accessToken);
      },
    });

    const result = await client.api('/me/memberOf').get();
    return (result.value as Group[]).map(group => group.displayName || '');
  } catch (error) {
    console.error('Error fetching user groups:', error);
    return [];
  }
};

export type UserAccessLevel = 'admin' | 'user' | 'none';

export const checkUserAccess = async (): Promise<UserAccessLevel> => {
  try {
    const groups = await getUserGroups();
    
    // Check for admin access first
    if (groups.includes(ADMIN_GROUP)) {
      return 'admin';
    }
    
    // Then check for user access
    if (groups.includes(USER_GROUP)) {
      return 'user';
    }
    
    // If not in either group, no access
    return 'none';
  } catch (error) {
    console.error('Error checking user access:', error);
    return 'none';
  }
};

export const hasAppAccess = async (): Promise<boolean> => {
  const accessLevel = await checkUserAccess();
  // Only allow access if user is either an admin or basic user
  return accessLevel !== 'none';
};

const logAuthFlow = (message: string) => {
  const now = new Date().toISOString();
  const logs = JSON.parse(localStorage.getItem('auth_debug_logs') || '[]');
  logs.push(`${now}: ${message}`);
  localStorage.setItem('auth_debug_logs', JSON.stringify(logs.slice(-20))); // Keep last 20 entries
  console.log(`[Auth] ${message}`);
};

export const clearAuthLogs = () => {
  localStorage.removeItem('auth_debug_logs');
};

export const ensureAuth = async (): Promise<AuthenticationResult | null> => {
  try {
    logAuthFlow('Starting auth flow');
    logAuthFlow(`Current URL: ${window.location.href}`);
    
    const redirectResult = await msalInstance.handleRedirectPromise();
    logAuthFlow(`Redirect result: ${redirectResult ? 'Present' : 'None'}`);
    
    if (redirectResult) {
      logAuthFlow('Processing redirect');
      logAuthFlow(`Redirect claims: ${JSON.stringify(redirectResult.idTokenClaims)}`);
      const hasAccess = await hasAppAccess();
      logAuthFlow(`Access check: ${hasAccess}`);
      if (!hasAccess) {
        logAuthFlow('Access denied');
        return null;
      }
      return redirectResult;
    }

    const accounts = msalInstance.getAllAccounts();
    logAuthFlow(`Found ${accounts.length} accounts`);
    const account = accounts[0];
    
    if (account) {
      logAuthFlow(`Account found: ${account.username}`);
      const hasAccess = await hasAppAccess();
      logAuthFlow(`Access check: ${hasAccess}`);
      if (!hasAccess) {
        logAuthFlow('Access denied');
        return null;
      }

      logAuthFlow('Getting token silently');
      try {
        const result = await msalInstance.acquireTokenSilent({
          ...loginRequestDynamics,
          account
        });
        logAuthFlow('Token acquired successfully');
        return result;
      } catch (error) {
        logAuthFlow(`Silent token error: ${error instanceof Error ? error.message : 'Unknown error'}`);
        throw error;
      }
    }

    logAuthFlow('No account, starting login redirect');
    await msalInstance.loginRedirect(loginRequestDynamics);
    return null;
  } catch (error) {
    logAuthFlow(`Error in auth flow: ${error instanceof Error ? error.message : 'Unknown error'}`);
    return null;
  }
};
