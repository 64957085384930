import React, { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';
import { getDynamicsAccessToken } from '../../auth/authService';

const { Text } = Typography;

export const TokenPage: React.FC = () => {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getDynamicsAccessToken();
        setToken(accessToken);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
    // Refresh token every 55 minutes (5 minutes before expiry)
    const interval = setInterval(fetchToken, 55 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Dataverse Access Token</h1>
      <Card title="Current Token" style={{ marginTop: '20px' }}>
        <Text copyable>{token}</Text>
      </Card>
      <Text type="secondary" style={{ display: 'block', marginTop: '10px' }}>
        Token refreshes automatically every 55 minutes. Last updated: {new Date().toLocaleTimeString()}
      </Text>
    </div>
  );
};

export default TokenPage;
