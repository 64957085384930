import React, { useEffect, useState } from 'react';
import { Tabs, Button, Modal, Form, DatePicker, Select, Radio, Space, Alert, Checkbox, Table, Tag } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { getUserLeaveBalance, getCurrentDynamicsUserId, listUserLeaveRequests } from '../../utils/api';
import { getCurrentUserIds } from '../../auth/authService';
import dayjs from 'dayjs';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

type RequestType = 'vacation' | 'appointment';

interface DayConfig {
  date: string;
  type: 'FULL' | 'AM' | 'PM';
  isStartDate?: boolean;
  isEndDate?: boolean;
}

interface AppointmentConfig {
  date: string;
  selectedHours: number[];
}

const BUSINESS_HOURS = Array.from({ length: 9 }, (_, i) => i + 9); // 9am to 5pm

const calculateDays = (days: DayConfig[]): number => {
  return days.reduce((total, day) => {
    return total + (day.type === 'FULL' ? 1 : 0.5);
  }, 0);
};

const calculateHours = (appointment: AppointmentConfig | null): number => {
  return appointment?.selectedHours.length || 0;
};

export const VacationTrackerPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState<DayConfig[]>([]);
  const [appointment, setAppointment] = useState<AppointmentConfig | null>(null);
  const [requestType, setRequestType] = useState<RequestType>('vacation');
  const [balanceData, setBalanceData] = useState<any>(null);
  const [leaveRequests, setLeaveRequests] = useState<any[]>([]);
  const [userIds, setUserIds] = useState<{
    azureId: string;
    email: string;
    dynamicsId: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get user IDs and Dynamics ID
        const { azureId, email } = await getCurrentUserIds();
        const dynamicsId = await getCurrentDynamicsUserId();
        
        setUserIds({
          azureId,
          email,
          dynamicsId
        });
        
        // Get leave balance
        const balanceResponse = await getUserLeaveBalance();
        setBalanceData(balanceResponse);
        
        // Get leave requests using the same Dynamics ID
        const requestsResponse = await listUserLeaveRequests(dynamicsId);
        setLeaveRequests(requestsResponse.value || []);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleOpenModal = () => {
    form.resetFields();
    setSelectedDays([]);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRequestTypeChange = (value: number) => {
    setRequestType(value === 1 ? 'vacation' : 'appointment');
    setSelectedDays([]);
    setAppointment(null);
    form.setFieldsValue({ dateRange: null, appointmentDate: null });
  };

  const handleDateRangeChange = (dates: any) => {
    if (requestType === 'appointment') return;
    if (!dates || !dates[0] || !dates[1]) {
      setSelectedDays([]);
      return;
    }

    const startDate = dates[0];
    const endDate = dates[1];
    const newDays: DayConfig[] = [];
    
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const isStartDate = currentDate.isSame(startDate, 'day');
      const isEndDate = currentDate.isSame(endDate, 'day');
      
      newDays.push({
        date: currentDate.format('YYYY-MM-DD'),
        type: 'FULL',
        isStartDate,
        isEndDate
      });
      currentDate = currentDate.add(1, 'day');
    }
    
    setSelectedDays(newDays);
  };

  const getAvailableTypes = (day: DayConfig) => {
    // If it's a single day selection
    if (day.isStartDate && day.isEndDate) {
      return ['FULL', 'AM', 'PM'];
    }
    // First day of multi-day selection
    if (day.isStartDate) {
      return ['FULL', 'PM'];
    }
    // Last day of multi-day selection
    if (day.isEndDate) {
      return ['FULL', 'AM'];
    }
    return ['FULL'];
  };

  const handleDayTypeChange = (date: string, type: 'FULL' | 'AM' | 'PM') => {
    setSelectedDays(prev => 
      prev.map(day => 
        day.date === date ? { ...day, type } : day
      )
    );
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Disable past dates and weekends
    return current && (
      current < dayjs().startOf('day') ||
      current.day() === 0 ||
      current.day() === 6
    );
  };

  const remainingDays = balanceData?.value?.[0] ? 
    balanceData.value[0].foxy_vacationdays - balanceData.value[0].foxy_usedvacationdays :
    0;

  const remainingHours = balanceData?.value?.[0] ?
    balanceData.value[0].foxy_appointmenthours - balanceData.value[0].foxy_usedappointmenthours :
    0;

  const totalSelectedDays = calculateDays(selectedDays);
  const isOverLimit = totalSelectedDays > remainingDays;

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1>Vacation Tracker</h1>
        <Button type="primary" onClick={handleOpenModal}>+ Create Request</Button>
      </div>

      <Modal
        title="Create Leave Request"
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            disabled={isOverLimit || selectedDays.length === 0}
          >
            Submit
          </Button>
        ]}
        width={700}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="requestType"
            label="Request Type"
            rules={[{ required: true }]}
          >
            <Select onChange={handleRequestTypeChange}>
              <Select.Option value={1}>Vacation</Select.Option>
              <Select.Option value={2}>Appointment</Select.Option>
            </Select>
          </Form.Item>

          {requestType === 'vacation' ? (
            <Form.Item
              name="dateRange"
              label="Date Range"
              rules={[{ required: true }]}
            >
              <RangePicker 
                style={{ width: '100%' }}
                onChange={handleDateRangeChange}
                disabledDate={disabledDate}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="appointmentDate"
              label="Appointment Date"
              rules={[{ required: true }]}
            >
              <DatePicker 
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                onChange={(date) => {
                  if (date) {
                    setAppointment({
                      date: date.format('YYYY-MM-DD'),
                      selectedHours: []
                    });
                  } else {
                    setAppointment(null);
                  }
                }}
              />
            </Form.Item>
          )}

          {requestType === 'vacation' && selectedDays.length > 0 && (
            <div style={{ marginBottom: '24px' }}>
              <h4>Configure Days:</h4>
              <Space direction="vertical" style={{ width: '100%' }}>
                {selectedDays
                  .filter(day => day.isStartDate || day.isEndDate) // Only show first and last days
                  .map(day => (
                    <div key={day.date} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <span style={{ width: '100px' }}>{dayjs(day.date).format('MMM D')}</span>
                      <Radio.Group 
                        value={day.type}
                        onChange={(e) => handleDayTypeChange(day.date, e.target.value)}
                      >
                        {getAvailableTypes(day).map(type => (
                          <Radio.Button key={type} value={type}>
                            {type === 'FULL' ? 'Full Day' : type === 'AM' ? 'Morning' : 'Afternoon'}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                  ))}
              </Space>
            </div>
          )}

          {requestType === 'appointment' && appointment && (
            <div style={{ marginBottom: '24px' }}>
              <h4>Select Hours:</h4>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '8px' }}>
                {BUSINESS_HOURS.map(hour => (
                  <Checkbox
                    key={hour}
                    checked={appointment.selectedHours.includes(hour)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAppointment(prev => prev ? {
                          ...prev,
                          selectedHours: [...prev.selectedHours, hour].sort((a, b) => a - b)
                        } : null);
                      } else {
                        setAppointment(prev => prev ? {
                          ...prev,
                          selectedHours: prev.selectedHours.filter(h => h !== hour)
                        } : null);
                      }
                    }}
                  >
                    {`${hour}:00 - ${hour + 1}:00`}
                  </Checkbox>
                ))}
              </div>
            </div>
          )}

          {(selectedDays.length > 0 || appointment) && (
            <Alert
              message={
                requestType === 'vacation' 
                  ? `Total Days Selected: ${totalSelectedDays}`
                  : `Hours Selected: ${calculateHours(appointment)} of ${remainingHours} available`
              }
              type={isOverLimit ? "error" : "info"}
              description={
                requestType === 'vacation'
                  ? isOverLimit 
                    ? `You have selected more days than your remaining balance (${remainingDays} days)`
                    : `You have ${remainingDays} days remaining`
                  : `You have ${remainingHours} appointment hours remaining`
              }
            />
          )}
        </Form>
      </Modal>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Balance" key="1">
          {balanceData?.value?.[0] && (
            <>
              <div style={{ fontSize: '18px', marginTop: '20px', marginBottom: '20px' }}>
                <p>
                  You have {balanceData.value[0].foxy_vacationdays - balanceData.value[0].foxy_usedvacationdays} vacation days remaining, 
                  and {balanceData.value[0].foxy_appointmenthours - balanceData.value[0].foxy_usedappointmenthours} appointment hours 
                  for {balanceData.value[0].foxy_year}
                </p>
              </div>
              
              <h2>Leave Requests</h2>
              <Table
                dataSource={leaveRequests}
                rowKey="foxy_leaverequestid"
                columns={[
                  {
                    title: 'Request ID',
                    dataIndex: 'foxy_name',
                    key: 'foxy_name',
                  },
                  {
                    title: 'Type',
                    dataIndex: 'foxy_leavetype',
                    key: 'foxy_leavetype',
                    render: (type) => (
                      <Tag color={type === 1 ? 'blue' : 'green'}>
                        {type === 1 ? 'Vacation' : 'Appointment'}
                      </Tag>
                    ),
                  },
                  {
                    title: 'Start Date',
                    dataIndex: 'foxy_startdate',
                    key: 'foxy_startdate',
                    render: (date) => dayjs(date).format('MMM D, YYYY h:mm A'),
                  },
                  {
                    title: 'End Date',
                    dataIndex: 'foxy_enddate',
                    key: 'foxy_enddate',
                    render: (date) => dayjs(date).format('MMM D, YYYY h:mm A'),
                  },
                  {
                    title: 'Duration',
                    dataIndex: 'foxy_duration',
                    key: 'foxy_duration',
                    render: (duration) => `${duration} ${duration === 1 ? 'day' : 'days'}`,
                  },
                  {
                    title: 'Status',
                    dataIndex: 'foxy_status',
                    key: 'foxy_status',
                    render: (status) => (
                      <Tag color={
                        status === 1 ? 'gold' :  // Pending
                        status === 2 ? 'green' : // Approved
                        status === 3 ? 'red' :   // Rejected
                        'default'
                      }>
                        {status === 1 ? 'Pending' :
                         status === 2 ? 'Approved' :
                         status === 3 ? 'Rejected' :
                         'Unknown'}
                      </Tag>
                    ),
                  },
                ]}
              />
            </>
          )}
        </TabPane>
        <TabPane tab="Debug Info" key="2">
          {userIds && (
            <div style={{ marginTop: '20px' }}>
              <h3>User IDs:</h3>
              <p><strong>Azure AD ID:</strong> {userIds.azureId}</p>
              <p><strong>Email:</strong> {userIds.email}</p>
              <p><strong>Dynamics 365 ID:</strong> {userIds.dynamicsId}</p>
            </div>
          )}
          <div style={{ marginTop: '20px' }}>
            <h3>Raw Data:</h3>
            <h4>Balance Data:</h4>
            <pre>{JSON.stringify(balanceData, null, 2)}</pre>
            <h4>Leave Requests:</h4>
            <pre>{JSON.stringify(leaveRequests, null, 2)}</pre>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default VacationTrackerPage;
