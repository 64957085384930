import React, { useCallback, useRef, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams, SelectionChangedEvent } from 'ag-grid-community';
import { formatCurrency } from '../../../utils/formatters';
import { RevenueService } from '../hooks/useRenewalCheckData';
import { getRenewalDisposition } from '../../../utils/constants/renewalDispositionMapper';
import { CustomContextMenu } from '../components/CustomContextMenu';
import { updateRenewalDisposition } from '../../../utils/api';
import { getBaseCustomerLabel } from '../../../utils/baseCustomerMapper';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { revenueTypeMapper } from '../../../utils/constants/revenueTypeMapper';

interface RenewalCheckGridProps {
  accounts: RevenueService[];
  onDisplayedRowsChanged: (count: number) => void;
  onAccountsUpdated?: (serviceIds: string[], newDisposition: number) => void;
}

interface ContextMenuState {
  show: boolean;
  x: number;
  y: number;
  rowData: any;
  columnField?: string;
}

interface PendingChange {
  rowId: string;
  newValue: number;
}

const getDispositionStyle = (disposition: number, hasPendingChange: boolean = false) => {
  const label = getRenewalDisposition(disposition);
  let color = '#d9d9d9';
  let background = '#2b2b2b';

  if (hasPendingChange) {
    color = '#ff4d4f';
    background = '#2a1215';
  } else if (label === 'Not In Renewal Period' || label === 'In Renewal Period') {
    color = '#52c41a';  // green
    background = '#162312';
  } else if (label === 'Opportunity Created') {
    color = '#1890ff';  // blue
    background = '#111d2c';
  } else if (label === 'Renewed by Infusion') {
    color = '#faad14';  // orange
    background = '#2b1d11';
  } else {
    // All others are red
    color = '#ff4d4f';  // red
    background = '#2a1215';
  }

  return { color, background };
};

const getRevenueTypeStyle = (revenueType: number) => {
  const label = revenueTypeMapper[revenueType];
  let color = '#d9d9d9';
  let background = '#2b2b2b';
  if (label === 'New') {
    color = '#52c41a';
    background = '#162312';
  } else if (label === 'Renewal') {
    color = '#faad14';
    background = '#2b1d11';
  } else if (label === 'Upsell') {
    color = '#1890ff';
    background = '#111d2c';
  }
  return { color, background };
};

const BaseCustomerCell = (props: any) => {
  if (!props.value && props.value !== 0) return null;
  const label = getBaseCustomerLabel(props.value);
  let color = '#d9d9d9';
  let background = '#2b2b2b';

  if (label === 'In Base') {
    color = '#52c41a';  // green
    background = '#162312';
  } else if (label === 'Non-Owned Base') {
    color = '#faad14';  // orange
    background = '#2b1d11';
  } else if (label === 'No' || label === 'Dumped') {
    color = '#ff4d4f';  // red
    background = '#2a1215';
  }

  return (
    <div style={{
      backgroundColor: background,
      border: `1px solid ${color}`,
      borderRadius: '4px',
      padding: '4px 8px',
      color: color,
      fontSize: '12px',
      fontWeight: 500,
      display: 'inline-block',
      lineHeight: '1',
      textAlign: 'center',
    }}>
      {label}
    </div>
  );
};

const DispositionCell = (props: any) => {
  if (!props.value && props.value !== 0) return null;
  const hasPendingChange = props.context.pendingChanges.some(
    (change: PendingChange) => change.rowId === props.data.foxy_serviceid
  );
  const pendingValue = hasPendingChange ? props.context.pendingChanges.find((change: PendingChange) => change.rowId === props.data.foxy_serviceid)?.newValue : props.value;
  const label = getRenewalDisposition(pendingValue);
  const { color, background } = getDispositionStyle(pendingValue, hasPendingChange);
  return (
    <div style={{
      backgroundColor: background,
      border: `1px solid ${color}`,
      borderRadius: '4px',
      padding: '4px 8px',
      color: color,
      fontSize: '12px',
      fontWeight: 500,
      display: 'inline-block',
      lineHeight: '1',
      textAlign: 'center',
      fontStyle: hasPendingChange ? 'italic' : 'normal',
    }}>
      {label}
    </div>
  );
};

const SfdcOppIdCell = (props: any) => {
  if (!props.value) return null;
  const handleClick = () => {
    props.context.filterBySfdcOppId(props.value);
  };
  return (
    <div onClick={handleClick} style={{
      backgroundColor: '#2b2b2b',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      padding: '4px 8px',
      color: '#d9d9d9',
      fontSize: '12px',
      fontWeight: 500,
      display: 'inline-block',
      lineHeight: '1',
      textAlign: 'center',
      cursor: 'pointer',
    }}>
      {props.value}
    </div>
  );
};

const RevenueTypeCell = (props: any) => {
  if (!props.value && props.value !== 0) return null;
  const label = revenueTypeMapper[props.value] || props.value;
  const { color, background } = getRevenueTypeStyle(props.value);
  return (
    <div style={{
      backgroundColor: background,
      border: `1px solid ${color}`,
      borderRadius: '4px',
      padding: '4px 8px',
      color: color,
      fontSize: '12px',
      fontWeight: 500,
      display: 'inline-block',
      lineHeight: '1',
      textAlign: 'center',
    }}>
      {label}
    </div>
  );
};

const OpportunityCell = (props: any) => {
  if (!props.value) return null;
  
  const opportunityId = props.data.foxy_Opportunity?.opportunityid;
  if (!opportunityId) return props.value;

  const opportunityUrl = `https://foxy.crm3.dynamics.com/main.aspx?appid=a5e9eec5-dda4-eb11-9441-000d3a848fc5&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=${opportunityId}`;
  
  return (
    <a 
      href={opportunityUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: '#1890ff',
        textDecoration: 'none',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.textDecoration = 'underline';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.textDecoration = 'none';
      }}
    >
      {props.value}
    </a>
  );
};

export const RenewalCheckGrid: React.FC<RenewalCheckGridProps> = ({ 
  accounts, 
  onDisplayedRowsChanged,
  onAccountsUpdated
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [pendingChanges, setPendingChanges] = useState<PendingChange[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedSfdcOppId, setSelectedSfdcOppId] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [contextMenu, setContextMenu] = useState<ContextMenuState>({
    show: false,
    x: 0,
    y: 0,
    rowData: null,
    columnField: undefined
  });

  const updateDispositions = async () => {
    setIsUpdating(true);
    setUpdateError(null);
    
    try {
      const api = gridRef.current?.api;
      if (!api) return;

      const updatePromises = pendingChanges.map(async (change) => {
        const row = api.getRowNode(change.rowId)?.data;
        if (!row) return;

        return updateRenewalDisposition({
          id: row.foxy_wonserviceid,
          foxy_renewaldisposition: change.newValue
        });
      });

      await Promise.all(updatePromises);

      // Update local state after successful API updates
      const updatedServiceIds = pendingChanges.map(change => change.rowId);
      const newDisposition = pendingChanges[0]?.newValue;
      onAccountsUpdated?.(updatedServiceIds, newDisposition);

      // Clear pending changes after successful update
      setPendingChanges([]);
      api.refreshCells({ force: true, columns: ['foxy_renewaldisposition'] });

    } catch (error) {
      console.error('Error updating dispositions:', error);
      setUpdateError(error instanceof Error ? error.message : 'Failed to update dispositions');
    } finally {
      setIsUpdating(false);
    }
  };

  const filterBySfdcOppId = useCallback((sfdcOppId: string) => {
    const api = gridRef.current?.api;
    if (api) {
      // Get current filter model to preserve other filters
      const currentFilterModel = api.getFilterModel();
      
      // Update only the SFDC Opp ID filter while preserving others
      api.setFilterModel({
        ...currentFilterModel,
        'foxy_Opportunity.foxy_sfdcoppid': { 
          type: 'equals',
          filter: sfdcOppId
        }
      });
      setIsFiltered(true);
    }
  }, []);

  const clearFilter = useCallback(() => {
    const api = gridRef.current?.api;
    if (api) {
      // Get current filter model
      const currentFilterModel = api.getFilterModel();
      
      // Remove only the SFDC Opp ID filter
      delete currentFilterModel['foxy_Opportunity.foxy_sfdcoppid'];
      
      // Set back the filter model with SFDC filter removed but others preserved
      api.setFilterModel({
        ...currentFilterModel,
        'foxy_Opportunity.foxy_sfdcoppid': {
          type: 'notBlank'
        }
      });
      
      setIsFiltered(false);
    }
  }, []);

  const isRowSelectable = useCallback((rowNode: any) => {
    if (!selectedSfdcOppId) return true;
    return rowNode.data?.foxy_Opportunity?.foxy_sfdcoppid === selectedSfdcOppId;
  }, [selectedSfdcOppId]);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const api = event.api;
    const selectedRows = api.getSelectedRows();
    setSelectedCount(selectedRows.length);

    if (selectedRows.length > 0) {
      const currentSfdcOppId = selectedRows[0]?.foxy_Opportunity?.foxy_sfdcoppid;
      
      if (!selectedSfdcOppId) {
        setSelectedSfdcOppId(currentSfdcOppId);
      } else if (currentSfdcOppId !== selectedSfdcOppId) {
        // Deselect rows with different SFDC Opp ID
        api.forEachNode(node => {
          if (node.data?.foxy_Opportunity?.foxy_sfdcoppid !== selectedSfdcOppId) {
            node.setSelected(false);
          }
        });
      }
    } else {
      setSelectedSfdcOppId(null);
    }
  }, [selectedSfdcOppId]);

  const handleDispositionChange = useCallback((rowId: string, newValue: number) => {
    const api = gridRef.current?.api;
    if (!api) return;
    const selectedRows = api.getSelectedRows();
    const rowsToUpdate = selectedRows.length > 0 ? selectedRows : [api.getRowNode(rowId)?.data];
    setPendingChanges(prev => {
      const filtered = prev.filter(change => !rowsToUpdate.some(row => row.foxy_serviceid === change.rowId));
      const newChanges = rowsToUpdate.map(row => ({ rowId: row.foxy_serviceid, newValue }));
      return [...filtered, ...newChanges];
    });
    api.refreshCells({ force: true, columns: ['foxy_renewaldisposition'] });
  }, []);

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest('.ag-row')) {
        event.preventDefault();
        const gridApi = gridRef.current?.api;
        if (gridApi) {
          const rowElement = target.closest('.ag-row');
          const rowId = rowElement?.getAttribute('row-id') || '';
          const rowNode = gridApi.getRowNode(rowId);
          const cell = target.closest('.ag-cell');
          const columnField = cell?.getAttribute('col-id') || undefined;
          if (rowNode) {
            setContextMenu({
              show: true,
              x: event.clientX,
              y: event.clientY,
              rowData: rowNode.data,
              columnField
            });
          }
        }
      }
    };

    const handleClick = (event: MouseEvent) => {
      if (contextMenu.show && !event.defaultPrevented) {
        setContextMenu(prev => ({ ...prev, show: false }));
      }
    };

    const gridContainer = gridContainerRef.current;
    if (gridContainer) {
      gridContainer.addEventListener('contextmenu', handleContextMenu);
      document.addEventListener('click', handleClick);
      return () => {
        gridContainer.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('click', handleClick);
      };
    }
  }, [contextMenu.show]);

  const onGridReady = useCallback(() => {
    const api = gridRef.current?.api;
    if (api) {
      const displayedNodes = api.getDisplayedRowCount();
      onDisplayedRowsChanged(displayedNodes);
    }
  }, [onDisplayedRowsChanged]);

  const columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 48,
      pinned: 'left',
      lockPosition: true,
      suppressMenu: true,
      suppressMovable: true
    },
    {
      field: 'foxy_Opportunity.foxy_sfdcoppid',
      headerName: 'SFDC Opp ID',
      width: 150,
      cellRenderer: SfdcOppIdCell,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    { field: 'foxy_Product.name', headerName: 'Product', width: 250 },
    {
      field: 'foxy_renewaldisposition',
      headerName: 'Renewal Disposition',
      width: 200,
      cellRenderer: DispositionCell,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      sortable: true,
      filter: true,
      valueGetter: (params: ValueGetterParams) => params.data?.foxy_renewaldisposition
    },
    { field: 'foxy_Account.name', headerName: 'Account', width: 300 },
    {
      field: 'foxy_Account.foxy_basecustomer',
      headerName: 'Base Customer',
      width: 150,
      cellRenderer: BaseCustomerCell,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      sortable: true,
      filter: true,
      valueGetter: (params: ValueGetterParams) => params.data?.foxy_Account?.foxy_basecustomer
    },
    {
      field: 'foxy_renewaleligible',
      headerName: 'Renewal Eligible',
      width: 130,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue);
          
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
        buttons: ['clear', 'apply'],
        closeOnApply: true
      },
      valueFormatter: params => {
        if (!params.value) return '';
        const date = new Date(params.value);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
      },
      comparator: (valueA: string, valueB: string) => {
        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
        return dateA - dateB;
      }
    },
    {
      field: 'foxy_contractend',
      headerName: 'Contract End',
      width: 130,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue);
          
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
        buttons: ['clear', 'apply'],
        closeOnApply: true
      },
      valueFormatter: params => {
        if (!params.value) return '';
        const date = new Date(params.value);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
      },
      comparator: (valueA: string, valueB: string) => {
        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
        return dateA - dateB;
      }
    },
    { field: 'foxy_AccountLocation.foxy_Building.foxy_fulladdress', headerName: 'Address', width: 300 },
    { field: 'foxy_term', headerName: 'Term', width: 100 },
    { field: 'foxy_quantity', headerName: 'Quantity', width: 100 },
    { field: 'foxy_mrr', headerName: 'MRR', width: 120, valueFormatter: params => formatCurrency(params.value) },
    {
      field: 'foxy_revenuetype',
      headerName: 'Revenue Type',
      width: 150,
      cellRenderer: RevenueTypeCell,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      valueGetter: (params: ValueGetterParams) => params.data?.foxy_revenuetype
    },
    { 
      field: 'foxy_Opportunity.name', 
      headerName: 'Opportunity', 
      width: 300,
      cellRenderer: OpportunityCell
    },
    {
      field: 'foxy_Opportunity.actualclosedate',
      headerName: 'Close Date',
      width: 120,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (!cellValue) return -1;
          const cellDate = new Date(cellValue);
          
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
        buttons: ['clear', 'apply'],
        closeOnApply: true
      },
      valueFormatter: params => {
        if (!params.value) return '';
        return new Date(params.value).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
      },
      comparator: (valueA: string, valueB: string) => {
        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;
        const dateA = new Date(valueA).getTime();
        const dateB = new Date(valueB).getTime();
        return dateA - dateB;
      }
    },
    { field: 'foxy_mrruptick', headerName: 'MRR Uptick', width: 120, valueFormatter: params => formatCurrency(params.value) },
    { field: 'foxy_tcv', headerName: 'TCV', width: 120, valueFormatter: params => formatCurrency(params.value) },
    {
      field: 'foxy_linemargin',
      headerName: 'Line Margin',
      width: 120,
      valueFormatter: params => params.value ? `${(params.value * 100).toFixed(1)}%` : ''
    },
    { field: 'foxy_renewaltype', headerName: 'Renewal Type', width: 200 }
  ];

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '16px' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          {isFiltered && (
            <button onClick={clearFilter} style={{
              backgroundColor: '#2b2b2b',
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
              padding: '8px 16px',
              color: '#d9d9d9',
              fontSize: '14px',
              fontWeight: 500,
              cursor: 'pointer',
            }}>
              Show All
            </button>
          )}
        </div>

        {pendingChanges.length > 0 && (
          <div style={{ 
            display: 'flex', 
            gap: '8px', 
            ...(isFiltered && {
              marginLeft: '24px',
              borderLeft: '1px solid #30363d',
              paddingLeft: '24px'
            })
          }}>
            <button
              onClick={() => {
                setPendingChanges([]);
                const api = gridRef.current?.api;
                if (api) {
                  api.refreshCells({ force: true, columns: ['foxy_renewaldisposition'] });
                }
              }}
              style={{
                backgroundColor: '#2b2b2b',
                border: '1px solid #faad14',
                borderRadius: '4px',
                padding: '8px 16px',
                color: '#faad14',
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              Discard {pendingChanges.length} Change{pendingChanges.length !== 1 ? 's' : ''}
            </button>
            <button
              onClick={updateDispositions}
              disabled={isUpdating}
              style={{
                backgroundColor: '#2b2b2b',
                border: '1px solid #ff4d4f',
                borderRadius: '4px',
                padding: '8px 16px',
                color: '#ff4d4f',
                fontSize: '14px',
                fontWeight: 500,
                cursor: isUpdating ? 'not-allowed' : 'pointer',
                opacity: isUpdating ? 0.7 : 1,
              }}
            >
              {isUpdating ? 'Updating...' : `Update ${pendingChanges.length} Record${pendingChanges.length !== 1 ? 's' : ''}`}
            </button>
          </div>
        )}

        {updateError && (
          <div style={{
            color: '#ff4d4f',
            marginLeft: '16px',
            fontSize: '14px'
          }}>
            Error: {updateError}
          </div>
        )}
      </div>
      <div 
        ref={gridContainerRef}
        className="ag-theme-quartz-dark" 
        style={{ 
          height: isFiltered ? 'calc(100vh - 250px)' : 'calc(100vh - 200px)',
          width: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
          '--ag-foreground-color': '#D1D5DA',
          '--ag-header-foreground-color': '#D1D5DA',
          '--ag-secondary-foreground-color': '#D1D5DA',
          '--ag-row-hover-color': 'rgb(24, 26, 27)',
          '--ag-selected-row-background-color': 'rgb(30, 32, 33)',
          '--ag-odd-row-background-color': 'rgb(22, 24, 25)',
          '--ag-background-color': '#1c2333',
          '--ag-header-background-color': '#1c2333',
          '--ag-border-color': '#30363d',
        } as React.CSSProperties}
      >
        <AgGridReact
          ref={gridRef}
          rowData={accounts}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          context={{
            filterBySfdcOppId,
            pendingChanges,
            handleDispositionChange
          }}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          onSelectionChanged={onSelectionChanged}
          isRowSelectable={isRowSelectable}
          getRowId={(params) => params.data.foxy_serviceid}
          maintainColumnOrder={true}
          onGridReady={onGridReady}
          onFilterChanged={() => {
            const api = gridRef.current?.api;
            if (api) {
              const displayedNodes = api.getDisplayedRowCount();
              onDisplayedRowsChanged(displayedNodes);
            }
          }}
          onModelUpdated={() => {
            const api = gridRef.current?.api;
            if (api) {
              const displayedNodes = api.getDisplayedRowCount();
              onDisplayedRowsChanged(displayedNodes);
            }
          }}
          initialState={{
            filter: {
              filterModel: {
                'foxy_Opportunity.foxy_sfdcoppid': {
                  type: 'notBlank'
                }
              }
            },
            sort: {
              sortModel: [
                {
                  colId: 'foxy_renewaleligible',
                  sort: 'desc'
                }
              ]
            }
          }}
        />
        {contextMenu.show && (
          <CustomContextMenu
            position={{ x: contextMenu.x, y: contextMenu.y }}
            onClose={() => setContextMenu(prev => ({ ...prev, show: false }))}
            rowData={contextMenu.rowData}
            columnField={contextMenu.columnField}
            selectedCount={selectedCount}
            onDispositionChange={(value: number) => {
              const rowId = contextMenu.rowData?.foxy_serviceid;
              if (rowId) {
                handleDispositionChange(rowId, value);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
