import { useState, useEffect, useCallback } from 'react';
import { listRevenueServices } from '../../../utils/api';
import { baseCustomerMap } from '../../../utils/baseCustomerMapper';

export interface RevenueService {
  foxy_totalinpayments: number;
  foxy_mrr: number;
  foxy_mrruptick: number;
  foxy_renewaltype: string;
  foxy_serviceid: string;
  foxy_revenuetype: number;
  foxy_linemargin: number;
  foxy_quantity: number;
  foxy_term: number;
  foxy_tcv: number;
  foxy_renewaldisposition: number;
  foxy_renewaleligible: string;
  foxy_contractend: string;
  foxy_Product: {
    name: string;
  };
  foxy_Account: {
    name: string;
    foxy_basecustomer: number;
  };
  foxy_Opportunity: {
    actualclosedate: string;
    name: string;
    foxy_sfdcoppid: string;
    opportunityid: string;
  };
  foxy_AccountLocation: {
    foxy_Building: {
      foxy_fulladdress: string;
    };
  };
  foxy_wonserviceid: string;
}

interface FilterOptions {
  searchTerm: string;
  selectedServices: string[];
  selectedResiduals: string[];
  selectedBaseCustomers: string[];
  showNeedsScrubbing: boolean;
  matchAllServices: boolean;
}

// Get all base customer values as strings for default selection
const allBaseCustomerValues = Object.keys(baseCustomerMap);

export const useRenewalCheckData = () => {
  const [accounts, setAccounts] = useState<RevenueService[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<RevenueService[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(() => ({
    searchTerm: localStorage.getItem('renewal_searchTerm') || '',
    selectedServices: JSON.parse(localStorage.getItem('renewal_selectedServices') || '[]'),
    selectedResiduals: JSON.parse(localStorage.getItem('renewal_selectedResiduals') || '[]').length > 0 
      ? JSON.parse(localStorage.getItem('renewal_selectedResiduals') || '[]')
      : ['612100001'], // Default to "In Renewal Period"
    selectedBaseCustomers: JSON.parse(localStorage.getItem('renewal_selectedBaseCustomers') || JSON.stringify(allBaseCustomerValues)),
    showNeedsScrubbing: JSON.parse(localStorage.getItem('renewal_showNeedsScrubbing') || 'false'),
    matchAllServices: JSON.parse(localStorage.getItem('renewal_matchAllServices') || 'false'),
  }));

  const buildFilterQuery = useCallback((selectedResiduals: string[]) => {
    if (!selectedResiduals.length) return '';
    
    const conditions = selectedResiduals.map(value => 
      `foxy_renewaldisposition eq ${value}`
    );
    return conditions.join(' or ');
  }, []);

  const applyBaseCustomerFilter = useCallback((data: RevenueService[]) => {
    if (!filterOptions.selectedBaseCustomers.length) return data;
    
    return data.filter(account => 
      account.foxy_Account?.foxy_basecustomer !== undefined && 
      filterOptions.selectedBaseCustomers.includes(account.foxy_Account.foxy_basecustomer.toString())
    );
  }, [filterOptions.selectedBaseCustomers]);

  const fetchAccounts = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);
      
      const storedResiduals = localStorage.getItem('renewal_selectedResiduals');
      const isFirstLoad = !storedResiduals;
      
      const filterQuery = isFirstLoad ? undefined : 
                         filterOptions.selectedResiduals.length === 0 ? null :
                         buildFilterQuery(filterOptions.selectedResiduals);
      
      const response = await listRevenueServices(filterQuery);
      
      const mappedAccounts = response.value.map((service: any) => ({
        foxy_totalinpayments: service.foxy_totalinpayments,
        foxy_mrr: service.foxy_mrr,
        foxy_mrruptick: service.foxy_mrruptick,
        foxy_renewaltype: service.foxy_renewaltype,
        foxy_serviceid: service.foxy_serviceid,
        foxy_revenuetype: service.foxy_revenuetype,
        foxy_linemargin: service.foxy_linemargin,
        foxy_quantity: service.foxy_quantity,
        foxy_term: service.foxy_term,
        foxy_tcv: service.foxy_tcv,
        foxy_renewaldisposition: service.foxy_renewaldisposition,
        foxy_renewaleligible: service.foxy_renewaleligible,
        foxy_contractend: service.foxy_contractend,
        foxy_Product: {
          name: service.foxy_Product?.name
        },
        foxy_Account: {
          name: service.foxy_Account?.name,
          foxy_basecustomer: service.foxy_Account?.foxy_basecustomer
        },
        foxy_Opportunity: {
          actualclosedate: service.foxy_Opportunity?.actualclosedate,
          name: service.foxy_Opportunity?.name,
          foxy_sfdcoppid: service.foxy_Opportunity?.foxy_sfdcoppid,
          opportunityid: service.foxy_Opportunity?.opportunityid
        },
        foxy_AccountLocation: {
          foxy_Building: {
            foxy_fulladdress: service.foxy_AccountLocation?.foxy_Building?.foxy_fulladdress
          }
        },
        foxy_wonserviceid: service.foxy_wonserviceid,
      }));
      
      setAccounts(mappedAccounts);
      setFilteredAccounts(applyBaseCustomerFilter(mappedAccounts));
    } catch (err) {
      setError('Error fetching accounts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [filterOptions.selectedResiduals, buildFilterQuery, applyBaseCustomerFilter]);

  useEffect(() => {
    setFilteredAccounts(applyBaseCustomerFilter(accounts));
  }, [accounts, applyBaseCustomerFilter]);

  // Disable the exhaustive-deps warning since we only want to fetch on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchAccounts(); }, []);

  const updateFilterOptions = (newOptions: Partial<FilterOptions>) => {
    setFilterOptions(prev => {
      const updated = { ...prev, ...newOptions };
      // Update localStorage for each changed option
      Object.entries(newOptions).forEach(([key, value]) => {
        localStorage.setItem(`renewal_${key}`, JSON.stringify(value));
      });
      return updated;
    });
  };

  const updateLocalAccounts = useCallback((serviceIds: string[], newDisposition: number) => {
    setAccounts(prevAccounts => {
      const updated = prevAccounts.map(account => 
        serviceIds.includes(account.foxy_serviceid)
          ? { ...account, foxy_renewaldisposition: newDisposition }
          : account
      );
      setFilteredAccounts(applyBaseCustomerFilter(updated));
      return updated;
    });
  }, [applyBaseCustomerFilter]);

  return {
    accounts: filteredAccounts,
    isLoading,
    error,
    filterOptions,
    updateFilterOptions,
    applyFilters: fetchAccounts,
    updateLocalAccounts
  };
};
