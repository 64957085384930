import React, { useState, useMemo } from 'react';
import { Table, Tag, Tooltip, Switch } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { TableRecord, WirelineRecord, ResidualRecord, MergedRecord } from '../../../types/residualTypes';
import DescriptionProductColumn from '../shared/DescriptionProductColumn';

export interface AccountTableProps {
  data: TableRecord[];
  showUnmerged?: boolean;
  onToggleUnmerged?: (checked: boolean) => void;
}

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  return date.toISOString().split('T')[0];
};

const DateRange: React.FC<{ startDate: string; endDate: string }> = ({ startDate, endDate }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const endDateObj = new Date(endDate);
  endDateObj.setHours(0, 0, 0, 0);
  const endDateColor = endDateObj < today ? 'red' : 'green';

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Tag color="blue">{formatDate(startDate)}</Tag>
      <span style={{ 
        color: '#666', 
        margin: '0 4px',
        display: 'flex',
        alignItems: 'center'
      }}>
        ⟶
      </span>
      <Tag color={endDateColor}>{formatDate(endDate)}</Tag>
    </div>
  );
};

const AccountTable: React.FC<AccountTableProps> = ({ data, showUnmerged, onToggleUnmerged }) => {
  const allParentKeys = useMemo(() => 
    data.filter(record => 'children' in record).map(record => record.key)
  , [data]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>(allParentKeys);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleExpandToggle = (checked: boolean) => {
    setIsCollapsed(checked);
    setExpandedRowKeys(checked ? [] : allParentKeys);
  };

  const handleExpandedRowsChange = (keys: readonly React.Key[]) => {
    setExpandedRowKeys([...keys]);
    setIsCollapsed(keys.length === 0);
  };

  const getTotalsDifference = (residualTotal: number, wirelineTotal: number) => {
    if (wirelineTotal === 0) return "No wireline records found";
    if (residualTotal === 0) return "No residual records found";
    
    const diff = residualTotal - wirelineTotal;
    if (diff > 0) {
      return <>Residuals are <span style={{ fontWeight: 'bold' }}>{diff.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span> higher</>;
    } else {
      return <>Wireline is <span style={{ fontWeight: 'bold' }}>{Math.abs(diff).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span> higher</>;
    }
  };

  const handleMasterListClick = (accountId: string) => {
    window.open(`/master-residual-list?search=${accountId}`, '_blank');
  };

  const columns: ColumnsType<TableRecord> = [
    {
      title: () => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Description/Product</span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: '16px' }}>
            {onToggleUnmerged && (
              <>
                <Switch 
                  size="small"
                  checked={showUnmerged}
                  onChange={onToggleUnmerged}
                />
                <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
                  De-merge Records
                </span>
              </>
            )}
            <Switch 
              size="small"
              checked={isCollapsed}
              onChange={handleExpandToggle}
            />
            <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
              {isCollapsed ? 'Expand All' : 'Collapse All'}
            </span>
          </div>
        </div>
      ),
      key: 'description',
      width: '45%',
      render: (_, record) => {
        if ('children' in record) {
          const residualTotal = record.totalResidualAmount;
          const wirelineTotal = record.totalWirelineCharges;
          const totalsMatch = Math.abs(residualTotal - wirelineTotal) < 0.01;

          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div style={{ fontWeight: 'bold' }}>
                <span style={{ color: '#1890ff' }}>{record.accountId}</span>
                <LinkOutlined 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMasterListClick(record.accountId);
                  }}
                  style={{ 
                    marginLeft: '4px',
                    cursor: 'pointer',
                    color: '#1890ff',
                    fontSize: '14px'
                  }}
                />
                {' - '}
                <span>{record.companyName}</span>
              </div>
              {totalsMatch ? (
                <Tooltip title={`Residuals: ${residualTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} | Wireline: ${wirelineTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}>
                  <Tag color="purple">
                    Matched Total: <span style={{ fontWeight: 'bold' }}>{residualTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                  </Tag>
                </Tooltip>
              ) : (
                <>
                  <Tag color="blue">
                    Residual Total: <span style={{ fontWeight: 'bold' }}>{residualTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                  </Tag>
                  <Tag color="green">
                    Wireline Total: <span style={{ fontWeight: 'bold' }}>{wirelineTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                  </Tag>
                  <Tag color="orange">
                    {getTotalsDifference(residualTotal, wirelineTotal)}
                  </Tag>
                </>
              )}
            </div>
          );
        }

        // For child rows, use DescriptionProductColumn
        return <DescriptionProductColumn record={record as (ResidualRecord | WirelineRecord | MergedRecord)} />;
      },
      onCell: (record) => {
        if ('children' in record) {
          return {
            colSpan: 5,
            style: { backgroundColor: '#fafafa' }
          };
        }
        return {};
      }
    },
    {
      title: 'Service Details',
      key: 'serviceDetails',
      width: '15%',
      render: (_, record) => {
        if ('children' in record) return null;

        const isMerged = record.type === 'merged';
        const isWireline = record.type === 'wireline';
        const mergedRecord = record as MergedRecord;
        const wirelineRecord = isWireline ? record as WirelineRecord : undefined;
        const residualRecord = record.type === 'residual' ? record as ResidualRecord : undefined;

        if (isMerged || isWireline) {
          const r = isMerged ? mergedRecord.wirelineRecord : wirelineRecord!;
          return (
            r.foxy_addressline1 && (
              <Tooltip title={`${r.foxy_addressline1}, ${r.foxy_city}, ${r.foxy_province} ${r.foxy_postalcode}`}>
                {`${r.foxy_addressline1}, ${r.foxy_city}, ${r.foxy_province}`}
              </Tooltip>
            )
          );
        }

        return residualRecord ? (
          <>
            Code: {residualRecord.foxyflow_charge_item_code}
          </>
        ) : null;
      },
      onCell: (record) => {
        if ('children' in record) {
          return { colSpan: 0 };
        }
        return {};
      }
    },
    {
      title: 'Contract Start/End',
      key: 'dates',
      width: '25%',
      render: (_, record) => {
        if ('children' in record) return null;

        const isMerged = record.type === 'merged';
        const isWireline = record.type === 'wireline';
        const mergedRecord = record as MergedRecord;
        const wirelineRecord = isWireline ? record as WirelineRecord : undefined;

        if (isMerged || isWireline) {
          const r = isMerged ? mergedRecord.wirelineRecord : wirelineRecord!;
          return (
            <DateRange 
              startDate={r.foxy_billingeffectivedate}
              endDate={r.foxy_estimatedenddate}
            />
          );
        }

        return null;
      },
      onCell: (record) => {
        if ('children' in record) {
          return { colSpan: 0 };
        }
        return {};
      }
    },
    {
      title: 'Site Name',
      key: 'company',
      width: '15%',
      render: (_, record) => {
        if ('children' in record) return null;

        const isMerged = record.type === 'merged';
        const isWireline = record.type === 'wireline';
        const mergedRecord = record as MergedRecord;
        const wirelineRecord = isWireline ? record as WirelineRecord : undefined;
        const residualRecord = record.type === 'residual' ? record as ResidualRecord : undefined;

        if (isMerged || isWireline) {
          const r = isMerged ? mergedRecord.wirelineRecord : wirelineRecord!;
          return r.foxy_sitename;
        }

        return residualRecord ? residualRecord.foxyflow_rogerscompanyname : null;
      },
      onCell: (record) => {
        if ('children' in record) {
          return { colSpan: 0 };
        }
        return {};
      }
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="key"
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: handleExpandedRowsChange
        }}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default AccountTable; 