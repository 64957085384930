import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Link } from 'react-router-dom';
import { UserAccessLevel } from '../../auth/authService';
import {
  AppstoreOutlined,
  UnorderedListOutlined,
  SearchOutlined,
  DollarOutlined,
  UserOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import './RadixNavMenu.css';

interface RadixNavMenuProps {
  userAccess: UserAccessLevel;
}

const RadixNavMenu: React.FC<RadixNavMenuProps> = ({ userAccess }) => {
  const menuItems = [
    {
      key: 'residual-check',
      label: 'Residual Account List',
      to: '/residual-check',
      icon: <UnorderedListOutlined />,
    },
    {
      key: 'master-residual',
      label: 'Search by Rogers Account',
      to: '/master-residual-list',
      icon: <SearchOutlined />,
    },
    {
      type: 'separator',
    },
    {
      key: 'renewals',
      label: 'Renewals',
      to: '/renewal-check',
      icon: <UnorderedListOutlined />,
    },
  ];

  // Admin-only menu items
  const adminMenuItems = [
    {
      type: 'separator',
    },
    {
      key: 'won-services',
      label: 'Won Services',
      to: '/won-services',
      icon: <UnorderedListOutlined />,
    },
    {
      key: 'incoming-wireline-payments',
      label: 'Callidus Wireline Payments',
      to: '/incoming-wireline-payments',
      icon: <UnorderedListOutlined />,
    },
    {
      key: 'product-compensation',
      label: 'Product Profit Dashboard',
      to: '/product-compensation',
      icon: <DollarOutlined />,
    },
    {
      key: 'token',
      label: 'Access Token',
      to: '/token',
      icon: <UserOutlined />,
    },
    {
      type: 'separator',
    },
    {
      key: 'file-upload',
      label: 'File Upload',
      icon: <FolderOutlined />,
      submenu: [
        {
          key: 'residual-upload',
          label: 'Residual Statement Upload',
          to: '/residual-upload',
          icon: <UploadOutlined />,
        },
        {
          key: 'wireline-upload',
          label: 'Wireline Statement Upload',
          to: '/wireline-upload',
          icon: <CloudUploadOutlined />,
        },
        {
          key: 'callidus-upload',
          label: 'Callidus Statement Upload',
          to: '/raw-excel-upload',
          icon: <CloudUploadOutlined />,
        },
      ],
    },
  ];

  const allMenuItems = [...menuItems, ...(userAccess === 'admin' ? adminMenuItems : [])];

  const renderMenuItem = (item: any) => {
    if (item.type === 'separator') {
      return <DropdownMenu.Separator className="nav-menu-separator" />;
    }

    if (item.submenu) {
      return (
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger className="nav-menu-item nav-menu-sub-trigger">
            <span className="nav-menu-icon">{item.icon}</span>
            {item.label}
          </DropdownMenu.SubTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.SubContent className="nav-menu-sub-content">
              {item.submenu.map((subItem: any) => (
                <DropdownMenu.Item key={subItem.key} className="nav-menu-item">
                  <Link to={subItem.to} className="nav-menu-link">
                    <span className="nav-menu-icon">{subItem.icon}</span>
                    {subItem.label}
                  </Link>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        </DropdownMenu.Sub>
      );
    }

    return (
      <DropdownMenu.Item key={item.key} className="nav-menu-item">
        <Link to={item.to} className="nav-menu-link">
          <span className="nav-menu-icon">{item.icon}</span>
          {item.label}
        </Link>
      </DropdownMenu.Item>
    );
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="nav-menu-trigger">
          <AppstoreOutlined />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className="nav-menu-content" sideOffset={5}>
          {allMenuItems.map(renderMenuItem)}
          <DropdownMenu.Arrow className="nav-menu-arrow" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default RadixNavMenu; 