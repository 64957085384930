import React from 'react';
import { Row, Col, Card, Statistic, Progress, Table } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DollarOutlined, UserOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const Dashboard: React.FC = () => {
  // Sample data for recent activities
  const recentActivities = [
    { key: '1', user: 'John Smith', action: 'Updated residual check', time: '2 minutes ago' },
    { key: '2', user: 'Sarah Johnson', action: 'Completed base scrub', time: '15 minutes ago' },
    { key: '3', user: 'Mike Wilson', action: 'Added new phone entry', time: '1 hour ago' },
    { key: '4', user: 'Emily Brown', action: 'Modified wireline payment', time: '2 hours ago' },
  ];

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
  ];

  return (
    <div style={{ padding: '8px 24px 16px' }}>
      <h2 style={{ 
        color: '#D1D5DA', 
        marginBottom: '8px',
        fontWeight: 500
      }}>
        Dashboard
      </h2>
      <div style={{ 
        color: '#8B949E', 
        fontSize: '14px', 
        marginBottom: '24px' 
      }}>
        Overview of system metrics and activities
      </div>

      {/* Stats Cards */}
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <Statistic
              title={<span style={{ color: '#8B949E' }}>Total Revenue</span>}
              value={234567}
              precision={2}
              valueStyle={{ color: '#D1D5DA' }}
              prefix={<DollarOutlined />}
              suffix="USD"
            />
            <div style={{ marginTop: '8px', color: '#52c41a' }}>
              <ArrowUpOutlined /> 8.5% increase
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <Statistic
              title={<span style={{ color: '#8B949E' }}>Active Accounts</span>}
              value={1234}
              valueStyle={{ color: '#D1D5DA' }}
              prefix={<UserOutlined />}
            />
            <div style={{ marginTop: '8px', color: '#52c41a' }}>
              <ArrowUpOutlined /> 12% increase
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <Statistic
              title={<span style={{ color: '#8B949E' }}>Completed Checks</span>}
              value={892}
              valueStyle={{ color: '#D1D5DA' }}
              prefix={<CheckCircleOutlined />}
            />
            <div style={{ marginTop: '8px', color: '#52c41a' }}>
              <ArrowUpOutlined /> 23% increase
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <Statistic
              title={<span style={{ color: '#8B949E' }}>Pending Reviews</span>}
              value={45}
              valueStyle={{ color: '#D1D5DA' }}
              prefix={<ClockCircleOutlined />}
            />
            <div style={{ marginTop: '8px', color: '#ff4d4f' }}>
              <ArrowDownOutlined /> 5% increase
            </div>
          </Card>
        </Col>
      </Row>

      {/* Progress Section */}
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        <Col span={8}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <div style={{ color: '#D1D5DA', marginBottom: '16px' }}>Monthly Goals</div>
            <Progress 
              percent={75} 
              status="active"
              strokeColor="#1890ff"
              trailColor="#2d3343"
            />
            <div style={{ color: '#8B949E', fontSize: '12px', marginTop: '8px' }}>
              75% of monthly target achieved
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <div style={{ color: '#D1D5DA', marginBottom: '16px' }}>System Health</div>
            <Progress 
              percent={98}
              status="success"
              strokeColor="#52c41a"
              trailColor="#2d3343"
            />
            <div style={{ color: '#8B949E', fontSize: '12px', marginTop: '8px' }}>
              All systems operational
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ background: '#1c2333', borderRadius: '8px' }}>
            <div style={{ color: '#D1D5DA', marginBottom: '16px' }}>Data Processing</div>
            <Progress 
              percent={45}
              status="active"
              strokeColor="#faad14"
              trailColor="#2d3343"
            />
            <div style={{ color: '#8B949E', fontSize: '12px', marginTop: '8px' }}>
              Processing daily updates
            </div>
          </Card>
        </Col>
      </Row>

      {/* Recent Activity Table */}
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <Card 
            bordered={false} 
            style={{ background: '#1c2333', borderRadius: '8px' }}
            title={<span style={{ color: '#D1D5DA' }}>Recent Activity</span>}
          >
            <Table 
              columns={columns} 
              dataSource={recentActivities}
              pagination={false}
              style={{
                background: '#1c2333',
                color: '#D1D5DA'
              }}
            />
          </Card>
        </Col>
      </Row>

      <style>
        {`
          .ant-table {
            background: #1c2333 !important;
            color: #D1D5DA !important;
          }
          .ant-table-thead > tr > th {
            background: #2d3343 !important;
            color: #D1D5DA !important;
            border-bottom: 1px solid #30363d !important;
          }
          .ant-table-tbody > tr > td {
            border-bottom: 1px solid #30363d !important;
            color: #D1D5DA !important;
          }
          .ant-table-tbody > tr:hover > td {
            background: #2d3343 !important;
          }
          .ant-card-head {
            border-bottom: 1px solid #30363d !important;
          }
          .ant-progress-text {
            color: #D1D5DA !important;
          }
        `}
      </style>
    </div>
  );
};

export default Dashboard; 