import React, { useEffect, useState } from 'react';
import { Layout, Avatar, Button, Modal, Form, Input } from 'antd';
import { UserOutlined, BugOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CommandPalette from '../../features/commandPalette/CommandPalette';
import { checkUserAccess, UserAccessLevel } from '../../auth/authService';
import { API_BASE_URL, getAuthHeaders } from '../../utils/api';
import RadixNavMenu from './RadixNavMenu';
import './Header.css';

const { Header } = Layout;

interface AppHeaderProps {
  quoteRequestId?: string;
  newProp?: string;
}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const [userAccess, setUserAccess] = useState<UserAccessLevel>('none');
  const [isBugModalVisible, setIsBugModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchUserAccess = async () => {
      const access = await checkUserAccess();
      setUserAccess(access);
    };
    fetchUserAccess();
  }, []);

  return (
    <Header className="app-header">
      {/* Menu and Logo section */}
      <div className="header-left">
        <RadixNavMenu userAccess={userAccess} />
        <Link to="/residual-check" style={{ textDecoration: 'none' }}>
          <div className="logo-container">
            <img src="/foxylogo.png" alt="Foxy Logo" className="logo-image" />
            <span className="logo-text">Ledger</span>
          </div>
        </Link>
      </div>

      {/* Command palette section - centered */}
      <div className="command-palette-container">
        <CommandPalette />
      </div>

      {/* Avatar section */}
      <div className="header-right">
        <Button 
          type="text"
          icon={<BugOutlined />}
          onClick={() => setIsBugModalVisible(true)}
          className="bug-submit-button"
        >
          Submit Bug
        </Button>
        <div className="user-avatar">
          <Avatar 
            icon={<UserOutlined />} 
            className="avatar-icon"
          />
        </div>

        {/* Bug Submit Modal */}
        <Modal
          title="Submit Bug Report"
          open={isBugModalVisible}
          onCancel={() => setIsBugModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={async (values) => {
              try {
                const headers = await getAuthHeaders();
                
                const bugData = {
                  ...values,
                  url: window.location.href,
                  userAgent: navigator.userAgent,
                  timestamp: new Date().toISOString()
                };
                
                const response = await fetch(`${API_BASE_URL}/createJiraIssue`, {
                  method: 'POST',
                  headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(bugData)
                });

                if (!response.ok) {
                  throw new Error('Failed to submit bug report');
                }

                const result = await response.json();
                
                Modal.success({
                  title: 'Bug Report Submitted',
                  content: (
                    <div>
                      <p>Thank you for your bug report!</p>
                      <p>You can track this issue at: <a href={result.issueUrl} target="_blank" rel="noopener noreferrer">{result.issueKey}</a></p>
                    </div>
                  ),
                });
                
                form.resetFields();
                setIsBugModalVisible(false);
              } catch (error) {
                Modal.error({
                  title: 'Error',
                  content: 'Failed to submit bug report. Please try again.',
                });
              }
            }}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter a title' }]}
            >
              <Input placeholder="Brief description of the issue" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please describe the issue' }]}
            >
              <Input.TextArea 
                rows={4}
                placeholder="Please provide details about what happened and what you expected to happen"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Header>
  );
};

export default AppHeader;
