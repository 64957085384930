import React, { useState, useEffect } from 'react';
import { Drawer, Input, Button, message } from 'antd';
import { WonService } from '../../../types/wonServices';
import dayjs from 'dayjs';
import { updateWonService } from '../../../utils/api';

const { TextArea } = Input;

interface ViewServiceDisputeModalProps {
    visible: boolean;
    onClose: () => void;
    service?: WonService;
}

const ViewServiceDisputeModal: React.FC<ViewServiceDisputeModalProps> = ({
    visible,
    onClose,
    service
}) => {
    const [claimId, setClaimId] = useState(service?.crc9f_claimid || '');

    useEffect(() => {
        setClaimId(service?.crc9f_claimid || '');
    }, [service]);

    const formatDate = (date?: string) => {
        return date ? dayjs(date).format('MM/DD/YY') : '-';
    };

    const handleSaveAndCreate = async () => {
        if (!service) return;

        const updateData = {
            id: service.foxy_wonserviceid,
            foxy_inpaymentstatus: 612100004,
            crc9f_claimid: claimId
        };
        
        console.log('Sending update data:', updateData);
        
        try {
            await updateWonService(updateData);
            message.success('Successfully updated to Disputed');
            onClose();
        } catch (error) {
            console.error('Error updating payment status:', error);
            message.error('Failed to update payment status');
        }
    };

    const renderField = (label: string, value: React.ReactNode, isInput: boolean = false) => (
        <div style={{ marginBottom: '16px' }}>
            <div style={{ marginBottom: '4px', fontWeight: 'bold' }}>{label}</div>
            {isInput ? (
                <Input 
                    value={value as string}
                    onChange={(e) => setClaimId(e.target.value)}
                    placeholder="Enter Claim ID"
                />
            ) : (
                <Input value={value as string} readOnly />
            )}
        </div>
    );

    return (
        <Drawer
            title="View Service Dispute"
            open={visible}
            onClose={onClose}
            width={400}
            extra={
                <Button type="primary" onClick={handleSaveAndCreate}>
                    Save & Create
                </Button>
            }
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {renderField('Transaction Date', formatDate(service?.foxy_Opportunity?.actualclosedate))}
                {renderField('Case Name', service?.foxy_serviceid || '-')}
                {renderField('Amount', service?.foxy_expectedcomp?.toFixed(2) || '-')}
                {renderField('TCV', service?.foxy_tcv?.toFixed(2) || '-')}
                {renderField('Account', service?.foxy_Account?.name || '-')}
                {renderField('Product', service?.foxy_Product?.name || '-')}
                {renderField('Opportunity', service?.foxy_Opportunity?.foxy_sfdcoppid || '-')}
                
                <div style={{ marginBottom: '16px' }}>
                    <div style={{ marginBottom: '4px', fontWeight: 'bold' }}>Claim Notes</div>
                    <TextArea 
                        value={service?.foxyflow_claimnotes || '-'} 
                        readOnly 
                        autoSize={{ minRows: 6 }}
                        style={{ width: '100%' }}
                    />
                </div>

                {renderField('Case ID', claimId, true)}
            </div>
        </Drawer>
    );
};

export default ViewServiceDisputeModal;
